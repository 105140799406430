import { SET_CURRENT_SCREEN, SET_IS_SHOW_MARKETING_POPUPS_LIST } from '../types'
import { LEFT_SIDEBAR_MENU_ITEMS, SUB_MENU_ITEMS } from '@library/utilities/constants/DashBoard/constants'

const initialState = {
    currentScreen: LEFT_SIDEBAR_MENU_ITEMS.KEYS.AUTO_TRIGGER_LOGIN + '-' + SUB_MENU_ITEMS.KEYS.LOGIN_SCREEN,
    isShowMarketingPopupsList: false,
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_SCREEN:
            return {
                ...state,
                currentScreen: action.payload,
            }
        case SET_IS_SHOW_MARKETING_POPUPS_LIST:
            return {
                ...state,
                isShowMarketingPopupsList: action.payload,
            }
        default:
            return state
    }
}
export default reducer
