import { createSlice } from '@reduxjs/toolkit'

const merchant_config_slice = createSlice({
    name: 'merchant_config_slice',
    initialState: {},
    reducers: {
        setMerchantConfigs: (state, action) => {
            state = action.payload
        },
    },
})

export const { setMerchantConfigs } = merchant_config_slice.actions
export default merchant_config_slice.reducer
