import { today, weekAgo } from '@library/utilities/helpers/helper'
import { createSlice } from '@reduxjs/toolkit'
interface analyticsStore {
    startDate: string
    endDate: string
}
const analytics_store_slice = createSlice({
    name: 'analytics_store_slice',
    initialState: {
        startDate: weekAgo,
        endDate: today,
    },
    reducers: {
        setAnalyticsDate: (state, action) => {
            state.startDate = action.payload.startDate
            state.endDate = action.payload.endDate
        },
    },
})
export const { setAnalyticsDate } = analytics_store_slice.actions
export default analytics_store_slice.reducer
