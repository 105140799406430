import { ANALYTICS_DATA_SOURCE, AnalyticsCharts } from '@library/utilities/constants/constants'
import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

const chartConfig = {
    [AnalyticsCharts.keyMetrics]: uuidv4(),
    [AnalyticsCharts.userSegmentation]: uuidv4(),
    [AnalyticsCharts.trends]: uuidv4(),
}
const analytics_store_slice = createSlice({
    name: 'analytics_store_slice',
    initialState: {
        source: ANALYTICS_DATA_SOURCE.SNOWPLOW,
        activeChartId: chartConfig[AnalyticsCharts.keyMetrics], //since keyMetrics is the default chart that opens
        chartID: { ...chartConfig },
        dateRange: {
            startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), // Default start date
            endDate: dayjs().format('YYYY-MM-DD'), // Default end date
        },
    },
    reducers: {
        setDataSource: (state, action) => {
            state.source = action.payload.source
        },
        setChartActionId: (state, action) => {
            const chartType = action.payload?.chartType
            state.activeChartId = chartConfig[chartType]
        },
        setStartDate: (state, action) => {
            state.dateRange.startDate = action.payload // Update startDate
        },
        setEndDate: (state, action) => {
            state.dateRange.endDate = action.payload // Update endDate
        },
        setDates: (state, action) => {
            const { startDate, endDate } = action.payload
            state.dateRange = { startDate, endDate } // Update both startDate and endDate together
        },
    },
})
export const { setDataSource, setChartActionId, setStartDate, setEndDate, setDates } = analytics_store_slice.actions
export default analytics_store_slice.reducer
