//Data Layer config data:

import { generateString } from '@library/utilities/helpers/helper'
import { MERCHANT_LOGO_SCALING_MIN_LIMIT, MERCHANT_LOGO_SCALING_MAX_LIMIT } from '../constants'

import {
    CAROUSEL_LOGO_FILE_SIZE_LIMIT_IN_KB,
    KWIKPASS_LOGO_FILE_SIZE_LIMIT_IN_KB,
    MERCHANT_LOGO_FILE_SIZE_LIMIT_IN_KB,
} from '../constants'

//LOGIN SCREEN:
const AUTO_TRIGGER_LOGIN_SCREEN_FORM_SECTIONS_L1 = {
    MODAL_COLOR: 'MODAL_COLOR',
    PROMOTIONAL_TEXT: 'PROMOTIONAL_TEXT',
    PROMOTIONAL_TEXT_COLOR: 'PROMOTIONAL_TEXT_COLOR',
    MERCHANT_LOGO: 'MERCHANT_LOGO',
    KWIKPASS_LOGO: 'KWIKPASS_LOGO',
    CAROUSEL_LOGO: 'CAROUSEL_LOGO',
    MERCHANT_LOGO_SCALING_DESKTOP: 'MERCHANT_LOGO_SCALING_DESKTOP',
    KWIKPASS_LOGO_SCALING_DESKTOP: 'KWIKPASS_LOGO_SCALING_DESKTOP',
    MERCHANT_LOGO_SCALING_MOBILE: 'MERCHANT_LOGO_SCALING_MOBILE',
    KWIKPASS_LOGO_SCALING_MOBILE: 'KWIKPASS_LOGO_SCALING_MOBILE',
    SUB_PROMOTIONAL_TEXT_CONTENT: 'SUB_PROMOTIONAL_TEXT_CONTENT',
    LOGIN_FORM_CONTENT: 'LOGIN_FORM_CONTENT',
}

const AUTO_TRIGGER_LOGIN_SCREEN_FORM_SECTIONS_L2 = {
    TEXT_COLOR: 'TEXT_COLOR',
    BORDER_COLOR: 'BORDER_COLOR',
    CONTENT_LIST: 'CONTENT_LIST',
    TITLE_MESSAGE_TEXT_LINE1: 'TITLE_MESSAGE_TEXT_LINE1',
    TITLE_MESSAGE_TEXT_LINE2: 'TITLE_MESSAGE_TEXT_LINE2',
    TITLE_MESSAGE_TEXT_COLOR: 'TITLE_MESSAGE_TEXT_COLOR',
    LABEL_TEXT: 'LABEL_TEXT',
    LABEL_TEXT_COLOR: 'LABEL_TEXT_COLOR',
    MARKETING_CHECKBOX_IS_ENABLED: 'MARKETING_CHECKBOX_IS_ENABLED',
    MARKETING_CHECKBOX_TEXT: 'MARKETING_CHECKBOX_TEXT',
    WHATS_APP_LOGIN_IS_ENABLED: 'WHATS_APP_LOGIN_IS_ENABLED',
    TRUE_CALLER_LOGIN_IS_ENABLED: 'TRUE_CALLER_LOGIN_IS_ENABLED',
}

const AUTO_TRIGGER_LOGIN_SCREEN_FORM_SECTIONS_L3 = {
    TITLE: 'TITLE',
    DESCRIPTION: 'DESCRIPTION',
}

export {
    AUTO_TRIGGER_LOGIN_SCREEN_FORM_SECTIONS_L1,
    AUTO_TRIGGER_LOGIN_SCREEN_FORM_SECTIONS_L2,
    AUTO_TRIGGER_LOGIN_SCREEN_FORM_SECTIONS_L3,
}

//COUPON SCREEN:
const AUTO_TRIGGER_COUPON_SCREEN_FORM_SECTIONS_L1 = {
    COUPON_TEXT: 'COUPON_TEXT',
    COUPON_CODE: 'COUPON_CODE',
    COUPON_MESSAGE: 'COUPON_MESSAGE',
    COUPON_TEXT_COLOR: 'COUPON_TEXT_COLOR',
    LOADER_MESSAGE_TEXT: 'LOADER_MESSAGE_TEXT',
    LOADER_MESSAGE_TEXT_COLOR: 'LOADER_MESSAGE_TEXT_COLOR',
    SUCCESS_MESSAGE_TEXT_LINE1: 'SUCCESS_MESSAGE_TEXT_LINE1',
    SUCCESS_MESSAGE_TEXT_LINE2: 'SUCCESS_MESSAGE_TEXT_LINE2',
    SUCCESS_MESSAGE_TEXT_COLOR: 'SUCCESS_MESSAGE_TEXT_COLOR',
}

//OTP SCREEN:
const AUTO_TRIGGER_OTP_SCREEN_FORM_SECTIONS_L1 = {
    HEADING_TEXT: 'HEADING_TEXT',
    HEADING_TEXT_COLOR: 'HEADING_TEXT_COLOR',
    LABEL_TEXT: 'LABEL_TEXT',
    LABEL_TEXT_COLOR: 'LABEL_TEXT_COLOR',
    EDIT_BUTTON_TEXT: 'EDIT_BUTTON_TEXT',
    EDIT_BUTTON_TEXT_COLOR: 'EDIT_BUTTON_TEXT_COLOR',
    EDIT_BUTTON_BORDER_COLOR: 'EDIT_BUTTON_BORDER_COLOR',
    EDIT_BUTTON_BG_COLOR: 'EDIT_BUTTON_BG_COLOR',
    ACTION_BUTTON_TEXT: 'ACTION_BUTTON_TEXT',
    ACTION_BUTTON_TEXT_COLOR: 'ACTION_BUTTON_TEXT_COLOR',
}

// UI layer config Data:
const LOGIN_SCREEN = {
    LABELS: {
        HEADING: 'Popup Design',
        BG_COLOR: 'Background Color',
        PROMOTIONAL_TEXT_COLOR: 'Promotional Text Color',
        PROMOTIONAL_MESSAGE: 'Promotional Text',
        LOGO_UPLOADS: 'Logo Uploads',
        MERCHANT_LOGO: 'Merchant Logo',
        KWIKPASS_LOGO: 'Kwikpass Logo',
        MERCHANT_LOGO_TITLE: `Merchant Logo (${MERCHANT_LOGO_FILE_SIZE_LIMIT_IN_KB}kB)`,
        KWIKPASS_LOGO_MANDATORY: `Kwikpass Logo (${KWIKPASS_LOGO_FILE_SIZE_LIMIT_IN_KB}kB)`,
        MANDATORY_MARK: '*',
        CAROUSEL_LOGO: 'Carousel Logo',
        CAROUSEL_LOGO_TITLE: `Carousel Logo (${CAROUSEL_LOGO_FILE_SIZE_LIMIT_IN_KB}kB)`,
        LOGO_SCALING_DESKTOP: `Logo Sizing (${MERCHANT_LOGO_SCALING_MIN_LIMIT}-${MERCHANT_LOGO_SCALING_MAX_LIMIT})`,
        LOGO_SCALING_MOBILE: `Logo Sizing (${MERCHANT_LOGO_SCALING_MIN_LIMIT}-${MERCHANT_LOGO_SCALING_MAX_LIMIT})`,
        MERCHANT_LOGO_SCALING: `Merchant Logo`,
        KWIKPASS_LOGO_SCALING: `Kwikpass Logo`,
        SUB_PROMOTIONAL_SECTION_HEADING: 'Promotional Tiles',
        SUB_PROMOTIONAL_TEXT_COLOR: 'Text Color',
        SUB_PROMOTIONAL_BORDER_COLOR: 'Border Color',
        SUB_PROMOTIONAL_SECTION_SUB_HEADING: 'Tile',
        SUB_PROMOTIONAL_SECTION_SUB_HEADING1: 'Tile 1',
        SUB_PROMOTIONAL_SECTION_SUB_HEADING2: 'Tile 2',
        SUB_PROMOTIONAL_SECTION_SUB_HEADING3: 'Tile 3',
        SUB_PROMOTIONAL_TITLE: 'Title',
        SUB_PROMOTIONAL_DESCRIPTION: 'Description',
        POLICY_SERVICE_HEADING: 'Policy Service Content',
        LABEL: 'Label',
        TERMS_OF_SERVICE_TEXT: 'Terms of service text',
        MERCHANT_POLICY_SERVICE_HEADING: 'Policies & Support',
        LINK: 'Link',
        LOGIN_FORM_HEADING: 'Login Box Content',
        TITLE_MESSAGE_HEADING: 'Title Message',
        TEXT_COLOR: 'Text Color',
        TEXT_LINE1: 'Text Line 1',
        TEXT_LINE2: 'Text Line 2',
        LABEL_TEXT_HEADING: 'Sub-Title Text',
        TEXT: 'Text',
        SUPPORT: 'Support',
        MARKETING_CHECKBOX: 'User Consent Checkbox',
        LOGIN_METHODS: 'Login Methods',
        WHATSAPP_OTP_LESS: 'WhatsApp',
        TRUECALLER: 'TrueCaller',
        LOADER_COLOR: 'Loader Color',
        PLACEHOLDER_TEXT: 'Phone box Text',
        PLACEHOLDER_COLOR: 'Placeholder Color',
        LINE_SEPERATOR_TEXT: 'Separator Text',
        LINE_SEPERATOR_COLOR: 'Separator Color',
        BOX_COLOR: 'Box color',
        COLOR: 'Color',
        MARKETING_CONSENT: 'Marketing Consent',
        DEFAULT_FONT: 'System Default',
        DEFAULT_BUTTON_ASSETS: 'Default Button Assets',
        DEFAULT_BUTTON_TEXT: 'Default Button Text',
        BUTTON_COLOR: 'Button Color',
        BUTTON_CUSTOMIZATION: 'Button Customization',
        GLOBAL_USER_LOGIN: 'Global user login',
    },
    MAX_CHARACTER_LIMIT: {
        PROMOTIONAL_TEXT: 130,
        CAROUSEL_TITLE: 40,
        CAROUSEL_DESCRIPTION: 90,
        PLACEHOLDER_TEXT: 30,
        LINE_SEPERATOR_TEXT: 20,
        TITLE_MESSAGE_LINE1: 40,
        TITLE_MESSAGE_LINE2: 40,
        MARKETING_CHECKBOX_TEXT: 60,
        POLICY_LABELS: 22,
        LOGIN_HEADING_TEXT: 25,
        LINKS: 80,
        LABEL_TEXT: 30,
        COUPON_SCREEN_LOADER_TEXT: 40,
        COUPON_SCREEN_LOADER_TEXT_SM: 25,
        COUPON_DESCRIPTION: 70,
        COUPON_TEXT: 50,
    },
}

const OTP_SCREEN = {
    LABELS: {
        SCREEN_HEADING: 'OTP Box Design',
        HEADING_CONTENT: 'Heading Content',
        BUTTON_CUSTOMISATION: 'Button Customisations',
        TEXT: 'Text',
        TEXT_COLOR: 'Text Color',
        LABEL_CONTENT: 'Sub-Heading Content',
        EDIT_BUTTON_CONTENT: 'Edit Button Content',
        BORDER_COLOR: 'Border Color',
        BACKGROUND_COLOR: 'Background Color',
        ACTION_BUTTON_CONTENT: 'Action Button Content',
        RESEND_OTP_CONTENT: 'Resend Otp Content',
        LOGGING_FOOTER: 'Logging Footer content',
    },
    MAX_CHARACTER_LIMIT: {
        HEADING_TEXT: 25,
        LABEL_TEXT: 30,
        EDIT_TEXT: 10,
        ACTION_TEXT: 24,
    },
}

const COUPON_SCREEN = {
    LABELS: {
        SCREEN_HEADING: 'Coupon Design',
        COUPON_CONTENT: 'Coupon Content',
        MESSAGE_CONTENT: 'Message Content',
        COUPON_DESCRIPTION: 'Coupon Description',
        COUPON_BACKGROUND_COLOR: 'Coupon Background Color',
        LOADER_COLOR: 'Loader Color',
        TEXT: 'Text',
        TEXT_COLOR: 'Text Color',
        COUPON_TEXT: 'Coupon Text',
        COUPON_CODE: 'Coupon Code',
        COUPON_MESSAGE: 'Coupon Message',
        LOADER_MESSAGE: 'Loader Message',
        SUCCESS_MESSAGE: 'Success Message',
        TEXT_LINE_1: 'Text Line 1',
        TEXT_LINE_2: 'Text Line 2',
        AUTO_DISMISS: 'Auto-close Screen',
        AUTO_DISMISS_SUB: 'Min. 2 and max. 60 (in seconds) ',
        AUTO_DISMISS_TOOLTIP: 'The coupon code screen will close according to the time set below.',
        AUTO_DISMISS_STEP: 1,
    },
    LIMITS: {
        AUTO_DISMISS_MIN: 0,
        AUTO_DISMISS_MAX: Number.MAX_VALUE,
    },
}

const defaultMarketingPopupSettings = {
    name: generateString(5),
    pages: {
        home: true,
        collection: true,
        pdp: true,
        customUrls: [],
    },
    platforms: ['WEB', 'MOBILE'],
    rules: {
        timeDelay: {
            isEnabled: true,
            secondsAfterLanding: 10,
        },
        pageScrolls: {
            isEnabled: false,
            scrollPercentage: 40,
        },
        websiteExitIntent: {
            isEnabled: false,
        },
        clickTrigger: {
            isEnabled: false,
            target: '',
            type: 'id',
        },
        manualTrigger: {
            isEnabled: false,
        },
        pagesVisited: {
            isEnabled: false,
            pages: 2,
            condition: 'equal',
        },
    },
    triggerValidations: {
        popupAppearLimit: {
            isEnabled: true,
            attempt: 1,
        },
        showToLoggedinUser: {
            isEnabled: false,
        },
    },
    userTargeting: {
        utm: {
            isEnabled: false,
            conditions: [],
        },
        cookie: {
            isEnabled: false,
            conditions: [],
        },
        visitsMerchant: {
            isEnabled: false,
            condition: 'equal',
            count: 1,
        },
        visitsKpNetwork: {
            isEnabled: false,
            condition: 'equal',
            count: 1,
        },
        merchantOrderCount: {
            isEnabled: false,
            condition: 'equal',
            count: 1,
        },
    },
}

//Validation Constants

const VALIDATION_CONSTANTS = {
    REQUIRED_FIELD: 'Please fill out this required field',
    MAX_CHARACTER_LIMIT_EXCEEDED: 'Maximum character limit exceeded',
    MIN_CHARACTER_LIMIT_EXCEEDED: 'Minimum character limit exceeded',
    CHECKBOX_VALIDATION: 'At least one checkbox must be checked',
    REQUIRED_MIN: 'Required',
    TRIGGER_REQ: 'Minimum one trigger is required',
}
const MARKETING_POPUP_TRIGGER_TOOLTIP = {
    AUTOMATIC_TRIGGERS: 'Displays the pop-up once per page when any of the below conditions is met',
    CLICK_TRIGGER: 'Defines specific HTML elements (class/ID) that, when clicked, will trigger the pop-up',
    TRIGGER_VALIDATION: 'Determines how many times the pop-up should be shown in a given session',
    PAGE_VISITED:
        'Triggers the pop-up when the user visits the exact nth page. Triggers the pop-up on all pages visited before the nth page. Triggers the pop-up on all pages visited after the nth page.',
}
export {
    AUTO_TRIGGER_COUPON_SCREEN_FORM_SECTIONS_L1,
    LOGIN_SCREEN,
    OTP_SCREEN,
    COUPON_SCREEN,
    AUTO_TRIGGER_OTP_SCREEN_FORM_SECTIONS_L1,
    VALIDATION_CONSTANTS,
    defaultMarketingPopupSettings,
    MARKETING_POPUP_TRIGGER_TOOLTIP,
}
