import { SET_PUBLISH_ACTION } from '../types'
import { DashboardActionsIntertface } from '@library/utilities/interface'

const initialState: DashboardActionsIntertface = {
    publishEventId: '',
    buttonType: 'Publish',
    isPublishedPopup: false,
    isDeepEqualityNeedsToCheck: false,
}
const reducer = (state: DashboardActionsIntertface = initialState, action) => {
    const actionData = action.payload as DashboardActionsIntertface
    switch (action.type) {
        case SET_PUBLISH_ACTION:
            return {
                ...state,
                ...actionData,
            }
        default:
            return state
    }
}
export default reducer
