export const pricing_details_confirmed = 'pricing_details_confirmed'
export const pricing_approval_pending = 'pricing_approval_pending'
export const pricing_details_captured = 'pricing_details_captured'
export const pricing_details_selected = 'pricing_details_selected'
export const create_basic_plan_for_code_first = 'createBasicPlanForCODFirst'
export const create_subscription = 'createSubscription'
export const kwikpass_app = 'kwikpass_app'
export const planType = {
    Basic: 'Basic',
    Premium: 'Premium',
    Free: 'Free',
    Advanced: 'Advanced',
    Custom: 'Custom',
}
