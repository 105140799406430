import { createSlice } from '@reduxjs/toolkit'

const merchantSlice = createSlice({
    name: 'merchantSlice',
    initialState: {
        merchantData: {
            merchantList: [],
            selectedMerchant: { id: null, name: null },
        },
    },
    reducers: {
        setMerchantList: (state, action) => {
            state.merchantData.merchantList = action.payload
        },
        setMerchant: (state, action) => {
            state.merchantData.selectedMerchant = action.payload
        },
    },
})

export const { setMerchantList, setMerchant } = merchantSlice.actions

export default merchantSlice.reducer
