import { LOGIN_MODAL_DATA_STORE } from '@library/utilities/interface/LoginPopup'
import { SET_AUTO_TRIGGER_POPUP_COMPONENT_ID } from '../types'
import { SCREEN_STORE_KEYS } from '@library/utilities/constants/constants'
const initialState: LOGIN_MODAL_DATA_STORE = {
    // Component Id
    [SCREEN_STORE_KEYS.COMPONENT_ID]: null,
    // Initial or old screen-configs
    INITIAL_LOGIN_POPUP_COMPONENTS: {},
    // New screen-configs
    NEW_LOGIN_POPUP_COMPONENTS: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTO_TRIGGER_POPUP_COMPONENT_ID:
            return {
                ...state,
                [SCREEN_STORE_KEYS.COMPONENT_ID]: action.payload,
            }

        case 'INITIAL_LOGIN_POPUP_COMPONENTS':
            return {
                ...state,
                ['INITIAL_LOGIN_POPUP_COMPONENTS']: action.payload,
                ['NEW_LOGIN_POPUP_COMPONENTS']: {
                    ...action.payload,
                },
            }

        case 'NEW_LOGIN_POPUP_COMPONENTS':
            return {
                ...state,
                ['NEW_LOGIN_POPUP_COMPONENTS']: action.payload,
            }

        default:
            return state
    }
}

export default reducer
