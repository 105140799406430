import { POPUP_STORES } from '@library/utilities/constants/constants'

const initialState = {
    [POPUP_STORES.AP2_COMPONENT_ID]: null,
    [POPUP_STORES.INITIAL_AP2_POPUP_COMPONENTS]: {},
    [POPUP_STORES.NEW_AP2_POPUP_COMPONENTS]: {},
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case POPUP_STORES.AP2_COMPONENT_ID:
            return {
                ...state,
                [POPUP_STORES.AP2_COMPONENT_ID]: action.payload,
            }
        case POPUP_STORES.NEW_AP2_POPUP_COMPONENTS:
            return {
                ...state,
                [POPUP_STORES.NEW_AP2_POPUP_COMPONENTS]: action.payload,
            }

        case POPUP_STORES.INITIAL_AP2_POPUP_COMPONENTS:
            return {
                ...state,
                [POPUP_STORES.INITIAL_AP2_POPUP_COMPONENTS]: action.payload,
                [POPUP_STORES.NEW_AP2_POPUP_COMPONENTS]: action.payload,
            }

        default:
            return state
    }
}
export default reducer
