import { createSlice } from '@reduxjs/toolkit'

const fonts_data_slice = createSlice({
    name: 'fonts_data_slice',
    initialState: { googleFontList: [] },
    reducers: {
        setGoogleFontsData: (state, action) => {
            state.googleFontList = action.payload
        },
    },
})

export const { setGoogleFontsData } = fonts_data_slice.actions
export default fonts_data_slice.reducer
