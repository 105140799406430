import React, { useEffect, useRef } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Progress } from 'gokwik-ui-kit'

// Import Pages for Routing
import './main.css'
import { Provider, useDispatch } from 'react-redux'
import store from './store'
import { setFilters } from './store/filters'
import { dashboardStore, filterStore } from '@gokwik/utilities'
import { ToastContainer } from 'react-toastify'
import { setUserData } from '@store/user'
const Dashboard = React.lazy(() => import('@pages/kwikpass-dashboard'))
const Faq = React.lazy(() => import('@pages/kwikpass-faq'))
const AnalyticsPage = React.lazy(() => import('@pages/kwikpass-analytics'))
const Integration = React.lazy(() => import('@pages/kwikpass-integration'))
const InitialiseStore = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setFilters(filterStore.getState().filters?.kwikpass || {}))
        // dispatch(setUserData(dashboardStore.getState().userData))
        const unsubcribe = dashboardStore.subscribe((state) => {
            dispatch(setUserData(state.userData))
        })
        return () => unsubcribe?.()
    }, [])
    return <></>
}

const queryClient = new QueryClient()

export default function Root(props: any) {
    const appContainerRef = useRef<HTMLDivElement>()
    useEffect(() => {
        setTimeout(() => {
            appContainerRef.current.style.maxWidth = `calc(100vw - ${
                document.getElementById('side-nav').getBoundingClientRect().width
            }px)`
        }, 100)
    }, [])
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <div
                    className='app-container max-h-screen h-screen  overflow-y-auto pt-20 px-5 w-full bg-[#003c7138]'
                    ref={appContainerRef}
                    style={{
                        maxWidth: `calc(100vw - ${document.getElementById('side-nav')?.offsetWidth}px)`,
                    }}
                >
                    <ToastContainer />
                    <InitialiseStore />
                    <BrowserRouter>
                        <React.Suspense fallback={<Progress />}>
                            <Routes>
                                <Route path='/kwikpass/configuration' Component={Dashboard} />
                                <Route path='/kwikpass/analytics' Component={AnalyticsPage} />
                                <Route path='/kwikpass/integration' Component={Integration} />
                                <Route path='/kwikpass/faq' Component={Faq} />
                            </Routes>
                        </React.Suspense>
                    </BrowserRouter>
                </div>
            </QueryClientProvider>
        </Provider>
    )
}
