import { POPUP_STORES } from '@library/utilities/constants/constants'
import { AP3Interface } from '@library/utilities/interface/MarketingPopup3'
const initialState: AP3Interface = {
    INITIAL_AP3_COMPONENTS: {},
    NEW_AP3_COMPONENTS: {},
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case POPUP_STORES.AP3_COMPONENTID:
            return {
                ...state,
                COMPONENT_ID: action.payload,
            }
        case POPUP_STORES.NEW_AP3_COMPONENTS:
            return {
                ...state,
                NEW_AP3_COMPONENTS: action.payload,
            }

        case POPUP_STORES.INITIAL_AP3_COMPONENTS:
            return {
                ...state,
                INITIAL_AP3_COMPONENTS: action.payload,
                NEW_AP3_COMPONENTS: action.payload,
            }

        default:
            return state
    }
}
export default reducer
