import { combineReducers } from 'redux'
import merchantConfigReducer from './reducers/merchantConfig'
import merchantThemeReducer from './reducers/merchantTheme'
import autoTriggerLoginReducer from './reducers/autoTriggerLogin'
import autoloadPopup4LoginReducer from './reducers/autoloadPopup4'
import marketingPopup5Reducer from './reducers/marketingPopup5'
import marketingPopup6Reducer from './reducers/marketingPopup6'
import autoloadPopup3Reducer from './reducers/autoLoadPopup3'
import marketingPopupsConfigs from './reducers/marketingPopupsConfigs'
import autoloadPopup4Reducer from './reducers/autoloadPopup_4'
import screenConfig from './reducers/screenConfig'
import viewMode from './reducers/previewViewMode'
import dashboardActions from '@store/kpDashboard/reducers/dashboardAction'
import autoloadPopup2Reducer from './reducers/autoloadPopup2'
const appReducer = combineReducers({
    merchantConfig: merchantConfigReducer,
    merchantTheme: merchantThemeReducer,
    autoTriggerLogin: autoTriggerLoginReducer,
    marketingPopup5: marketingPopup5Reducer,
    marketingPopup6: marketingPopup6Reducer,
    autoloadPopup4Login: autoloadPopup4LoginReducer,
    autoloadPopup4: autoloadPopup4Reducer,
    autoloadPopup3: autoloadPopup3Reducer,
    autoloadPopup2: autoloadPopup2Reducer,
    screenConfig: screenConfig,
    viewMode: viewMode,
    dashboardActions: dashboardActions,
    marketingPopupsConfigs: marketingPopupsConfigs,
})
const rootReducer = (state, action) => {
    return appReducer(state, action)
}

export default rootReducer
