import { POPUP_STORES } from '@library/utilities/constants/constants'
import { AP4_DATA_STORE } from '@library/utilities/interface/MarketingPopupAP4'

const initialState: AP4_DATA_STORE = {
    INITIAL_AP4_COMPONENTS: {},
    NEW_AP4_COMPONENTS: {},
}

const reducer = (state = initialState, action): AP4_DATA_STORE => {
    switch (action.type) {
        case POPUP_STORES.AP4_COMPONENTID:
            return {
                ...state,
                COMPONENT_ID: action.payload,
            }
        case POPUP_STORES.INITAL_AP4_COMPONENTS:
            return {
                ...state,
                INITIAL_AP4_COMPONENTS: action.payload,
                NEW_AP4_COMPONENTS: action.payload,
            }
        case POPUP_STORES.NEW_AP4_COMPONENTS:
            return {
                ...state,
                NEW_AP4_COMPONENTS: action.payload,
            }
        default:
            return state
    }
}

export default reducer
