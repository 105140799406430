import { SearchFilters } from '../interface'
import { CustomDataCaptureField } from '../interface/common'

const searchFilters: SearchFilters[] = [
    {
        id: 69,
        label: 'Date Range',
        key: 'startDate/endDate',
        is_dynamic: false,
        input_details: {
            input_type: 'date-range',
        },
    },
]

const DESKTOP_VIEW_MODE = 'desktop'
const MOBILE_VIEW_MODE = 'mobile'
export const ICON_SIZE_RATIO = '1:1'
export const DEFAULT_ICON_WIDTH_DESKTOP_LOGIN_MODAL = '128' // in pixels
export const DEFAULT_ICON_WIDTH_MOBILE_LOGIN_MODAL = '80' // in pixels
export const DEFAULT_ICON_WIDTH_DESKTOP_AP2 = '128' // in pixels
export const DEFAULT_ICON_WIDTH_MOBILE_AP2 = '80' // in pixels
export const DEFAULT_ICON_WIDTH_DESKTOP_AP3 = '155' // in pixels
export const DEFAULT_ICON_WIDTH_MOBILE_AP3 = '100' // in pixels
export const DEFAULT_ICON_WIDTH_SHOPIFY_LOGIN_MODAL = '120' // in pixels
export const KWIKPASS_LOGO_SCALING_MAX_LIMIT = 2.0
export const KWIKPASS_LOGO_SCALING_MIN_LIMIT = 0.5
export const MERCHANT_LOGO_SCALING_MAX_LIMIT = 2.0
export const MERCHANT_LOGO_SCALING_MIN_LIMIT = 0.5
export const LOGO_SCALING_DELTA = 0.1
export const DEFAULT_COLOR = '#fff'
export const KWIKPASS_LOGO_FILE_SIZE_LIMIT_IN_KB = '500'
export const MERCHANT_LOGO_FILE_SIZE_LIMIT_IN_KB = '500'
export const CAROUSEL_LOGO_FILE_SIZE_LIMIT_IN_KB = '500'
export const FONT_SIZE_MAX_LIMIT_IN_PX = 24
export const FONT_SIZE_MIN_LIMIT_IN_PX = 1
export const CUSTOM_MERCHANT_PLATFORM = 'custom'
export const SHOPIFY_PLATFORM = 'shopify'
export const CUSTOM_SHOPIFY_MERCHANT_PLATFORM = 'custom_shopify'
export const CUSTOM_URL_SHOW_DISPLAY = 'Show'
export const CUSTOM_URL_HIDE_DISPLAY = 'Hide'

export const ANALYTICS_DATA_SOURCE = {
    SHOPIFY: 'SHOPIFY',
    SNOWPLOW: 'SNOWPLOW',
}
export const AnalyticsCharts = {
    keyMetrics: 'keyMetrics',
    userSegmentation: 'userSegmentation',
    trends: 'trends',
}

export const FONT_WEIGHTS = {
    normal: '400',
    bold: '700',
}
export const FontConfigs = {
    size: {
        large: '24px',
        medium: '16px',
        small: '12px',
    },
    weight: {
        normal: '400',
        medium: '500',
        lightbold: '600',
        bold: '700',
    },
    disableColor: '#D9D9D9',
}
// Since data not available via backend this is handled by FE:
// this should be consistent with plugin code
export const CUSTOM_FIELDS = {
    EMAIL: 'Email',
    NAME: 'Name',
    DOB: 'DOB',
    GENDER: 'Gender',
    PROFESSION: 'Profession',
}

export const getDefaultCustomFields = (): CustomDataCaptureField[] => {
    return Object.keys(CUSTOM_FIELDS).map((key) => {
        return {
            field_name: CUSTOM_FIELDS[key],
            is_active: false,
            is_mandatory: false,
        }
    })
}

const COMPONENT_KEYS = {
    LOGIN_MODAL_ASSETS: 'LOGIN_MODAL_ASSETS',
    AUTO_LOAD_POPUP2: 'AUTO_LOAD_POPUP2',
    AUTO_LOAD_POPUP3: 'AUTO_LOAD_POPUP3',
    AUTO_LOAD_POPUP4: 'AUTO_LOAD_POPUP4',
    AUTO_LOAD_POPUP5: 'AUTO_LOAD_POPUP5',
    AUTO_LOAD_POPUP6: 'AUTO_LOAD_POPUP6',
    COMPONENT_ID: 'COMPONENT_ID',
}

const SCREEN_STORE_KEYS = {
    // Key for parsers
    LOGIN_SCREEN: 'LOGIN_SCREEN',
    OTP_SCREEN: 'OTP_SCREEN',
    COUPON_SCREEN: 'COUPON_SCREEN',
    // Flag to check if Component Config does exist
    COMPONENT_ID: 'COMPONENT_ID',
    // Store Key for Initial/old screen configs
    INITIAL_LOGIN_SCREEN: 'INITIAL_LOGIN_SCREEN',
    INITIAL_OTP_SCREEN: 'INITIAL_OTP_SCREEN',
    INITIAL_COUPON_SCREEN: 'INITIAL_COUPON_SCREEN',
    // Store Key for New screen configs from form-values
    NEW_LOGIN_SCREEN: 'NEW_LOGIN_SCREEN',
    NEW_OTP_SCREEN: 'NEW_OTP_SCREEN',
    NEW_COUPON_SCREEN: 'NEW_COUPON_SCREEN',
}

const MERCHANT_CONFIG_STORE_KEYS = {
    KWIKPASS_ENABLED: 'kwikpassEnabled',
    HOST: 'host',
    KWIKPASS_ENABLED_INITIAL: 'kwikpassEnabledInitial',
    PLATFORM: 'platform',
    CATEGORY: 'category',
}

// TOOD: send below config to individual folders

const AUTO_TRIGGER_LOGIN_DEFAULT_VALUES = {
    LOGIN_SCREEN: {
        MARKETING_CHECKBOX_IS_ENABLED: false,
        MARKETING_CHECKBOX_TEXT: 'Notify me for any updates & offers',
        LOGO_SCALING: '1',
    },
}

const GENERAL_CONST_KEYS = {
    DONE: 'done',
    ERROR: 'error',
    REMOVED: 'removed',
    FILE_UPLOAD_ERROR: 'File upload failed',
}

const PAGELIMIT = 15

enum LOGIN_SOURCE_CATEGORIES {
    'login_modal' = 'Login popup',
    'sso_login' = 'Auto identified user',
    'login_autoload' = 'Marketing popup',
    'shopify_login_modal' = 'Email input modal',
    'All' = 'all login sources',
}

const CHART_KEYS = {
    NEW_LOGIN_SPLITS: 'new_login_splits',
    OTP_FUNNEL: 'otp_funnel',
    KWIKPASS_VALUE_DELIVERY: 'kwikpass_value_delivery',
    TRENDS: 'kwikpass-trends',
    LOGINS_TRENDS: 'kwikpass-logins-trends',
}

const FILE_TYPES = {
    IMAGE_FILE_TYPES: 'IMAGE_FILE_TYPES',
}

const FILE_TYPES_LIST = {
    [FILE_TYPES.IMAGE_FILE_TYPES]: ['image/jpeg', 'image/png', 'image/svg+xml'],
}

const CHANNEL_FILTER = {
    ALL_CHANNEL: 'All Channels',
    Whatsapp: 'Whatsapp',
    EMAIL: 'Email',
    SMS: 'SMS',
}
const CHANNEL_FILTER_CONFIG = {
    ALL_CHANNEL: { label: 'All Channels', value: '' },
    Whatsapp: { label: 'Whatsapp', value: '' },
    EMAIL: { label: 'Email', value: '' },
    SMS: { label: 'SMS', value: '' },
}

const POPUP_STORES = {
    INITIAL_MP6_POPUP_COMPONENTS: 'INITIAL_MP6_POPUP_COMPONENTS',
    NEW_MP6_POPUP_COMPONENTS: 'NEW_MP6_POPUP_COMPONENTS',
    MP6_POPUP_COMPONENTID: 'MP6_POPUP_COMPONENTID',

    INITIAL_MP5_POPUP_COMPONENTS: 'INITIAL_MP5_POPUP_COMPONENTS',
    NEW_MP5_POPUP_COMPONENTS: 'NEW_MP5_POPUP_COMPONENTS',
    MP5_POPUP_COMPONENTID: 'MP5_POPUP_COMPONENTID',

    INITAL_AP4_COMPONENTS: 'INITIAL_AP4_COMPONENTS',
    NEW_AP4_COMPONENTS: 'NEW_AP4_COMPONENTS',
    AP4_COMPONENTID: 'AP4_COMPONENTID',

    NEW_AP3_COMPONENTS: 'NEW_AP3_COMPONENTS',
    INITIAL_AP3_COMPONENTS: 'INITIAL_AP3_COMPONENTS',
    AP3_COMPONENTID: 'AP3_COMPONENTID',
    AP2_COMPONENT_ID: 'AP2-COMPONENT_ID',
    INITIAL_AP2_POPUP_COMPONENTS: 'INITIAL_AP2_POPUP_COMPONENTS',
    NEW_AP2_POPUP_COMPONENTS: 'NEW_AP2_POPUP_COMPONENTS',
}

const Fallback_Coupon_Asset = {
    text: 'Flat 15% Off',
    color: '#FFF',
    auto_apply: true,
    code_label: 'Code',
    description: 'Will be auto-applied at checkout',
    background_color: '#FA551A',
}

const platformSupport = [
    { label: 'Web', value: 'WEB' },
    { label: 'Mobile', value: 'MOBILE' },
]

const No_Data_Message_For_Marketing_Popups_List = {
    All: 'No saved popups. To get started, please click on the create new popup button.',
    Active: 'No Active Popups',
    Inactive: 'No Inactive Popups',
}
const phoneFormatOptions = [
    { value: '+91', label: 'With country code (eg. +91 format)' },
    { value: '', label: 'Without country code' },
    { value: '91', label: 'With country code (eg. 91 format)' },
]
const plusNinetyOne = '+91'

const campSegGroupByCols = [
    {
        title: 'UTM Source',
        id: 'mktSource',
    },
    {
        title: 'UTM Medium',
        id: 'mktMedium',
    },
    {
        title: 'UTM Campaign',
        id: 'mktCampaign',
    },
]
const compaignSegmentationColumns = [
    {
        title: 'Attribution',
        id: 'source',
    },
    {
        title: 'UTM Source',
        id: 'mktSource',
    },
    {
        title: 'UTM Medium',
        id: 'mktMedium',
    },
    {
        title: 'UTM Campaign',
        id: 'mktCampaign',
    },
    {
        title: 'Total Session',
        id: 'websiteLanding',
    },
    {
        title: 'Identified Sessions',
        id: 'usersIdentified',
    },
    {
        title: 'Checkout Initiated',
        id: 'checkoutInitiated',
    },
    {
        title: 'SSO',
        id: 'sso',
    },
    {
        title: 'Order Placed',
        id: 'orderPlaced',
    },
    {
        title: 'GMV Impacted',
        id: 'GMV',
    },
]
const userSegmentationColumns = [
    {
        title: 'Phone Number',
        id: 'phone',
    },
    {
        title: 'Login Source',
        id: 'category',
    },
    {
        title: 'Device',
        id: 'device_type',
    },
    {
        title: 'Email',
        id: 'email',
    },
    {
        title: 'Last Viewed Page',
        id: 'last_visited_page_type',
    },
    {
        title: 'Timestamp',
        id: 'date',
    },
    {
        title: 'Last Visited URL',
        id: 'last_visited_page_url',
    },
    {
        title: 'IP Address',
        id: 'ip_address',
    },
]
const ONBOARDING_STATES = [
    'auto_theme_extension_selected',
    'manual_theme_extension_selected',
    'connect_with_support',
    'login_preferences_activated',
]
const OnboardingEndpoint = '/onboarding/kp/onboarding-steps'
const MyPlanEndpoint = '/kwikpass/yourplan'
const AnalyticsEndpoint = '/kwikpass/analytics'

const WHEEL_DESIGN = {
    fullWheel: 'fullWheel',
    croppedWheel: 'croppedWheel',
}

export const KPMerchantPlatform = {
    shopify: 'shopify',
    custom_full_stack: 'custom full stack',
    custom_headless: 'custom headless',
    custom_shopify: 'custom shopify',
}

export const KPPlatform = {
    web: 'web',
    app: 'app',
}

export {
    OnboardingEndpoint,
    ONBOARDING_STATES,
    CHANNEL_FILTER,
    searchFilters,
    COMPONENT_KEYS,
    AUTO_TRIGGER_LOGIN_DEFAULT_VALUES,
    LOGIN_SOURCE_CATEGORIES,
    POPUP_STORES,
    PAGELIMIT,
    GENERAL_CONST_KEYS,
    FILE_TYPES,
    FILE_TYPES_LIST,
    SCREEN_STORE_KEYS,
    MERCHANT_CONFIG_STORE_KEYS,
    DESKTOP_VIEW_MODE,
    MOBILE_VIEW_MODE,
    CHART_KEYS,
    Fallback_Coupon_Asset,
    platformSupport,
    No_Data_Message_For_Marketing_Popups_List,
    phoneFormatOptions,
    plusNinetyOne,
    userSegmentationColumns,
    compaignSegmentationColumns,
    WHEEL_DESIGN,
    MyPlanEndpoint,
    AnalyticsEndpoint,
    campSegGroupByCols,
}
