import { generateString } from '@library/utilities/helpers/helper'
import {
    POPUP_TYPE,
    INITIAL_MARKETING_POPUP_CONFIGURATIONS,
    NEW_MARKETING_POPUP_CONFIGURATIONS,
} from '../types'
import { defaultMarketingPopupSettings } from '@library/utilities/constants/AutoTrigger/constants'
const initialState = {
    [POPUP_TYPE]: null,
    [NEW_MARKETING_POPUP_CONFIGURATIONS]: {
        ...defaultMarketingPopupSettings,
        name: generateString(5),
    },
    [INITIAL_MARKETING_POPUP_CONFIGURATIONS]: {
        ...defaultMarketingPopupSettings,
        name: generateString(5),
    },
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case POPUP_TYPE:
            return {
                ...state,
                [POPUP_TYPE]: action.payload,
            }
        case INITIAL_MARKETING_POPUP_CONFIGURATIONS:
            return {
                ...state,
                [INITIAL_MARKETING_POPUP_CONFIGURATIONS]: action.payload,
                [NEW_MARKETING_POPUP_CONFIGURATIONS]: action.payload,
            }
        case NEW_MARKETING_POPUP_CONFIGURATIONS:
            return {
                ...state,
                [NEW_MARKETING_POPUP_CONFIGURATIONS]: {
                    ...state[NEW_MARKETING_POPUP_CONFIGURATIONS],
                    ...action.payload
                }
            }
        default:
            return state
    }
}
export default reducer
