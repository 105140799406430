import { dashboardStore } from '@gokwik/utilities'
import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        userData: dashboardStore.getState().userData,
    },
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload
        },
    },
})

export const { setUserData } = userSlice.actions

export default userSlice.reducer
