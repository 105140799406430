import { ReactNode } from 'react'
import dayjs from 'dayjs'
import { message } from 'gokwik-ui-kit'

import { SearchFilters, FilterCreatorModel } from '../interface'
import {
    COMPONENT_KEYS,
    FILE_TYPES_LIST,
    FontConfigs,
    LOCAL_STORAGE_KEYS,
    MARKETING_POPUP_LOGIN_STATIC_VALUES,
    POPUP_STORES,
} from '../constants/constants'
import { SCREEN_STORE_KEYS, MERCHANT_CONFIG_STORE_KEYS } from '@library/utilities/constants/constants'
import { MenuItemType, ItemType } from 'antd/es/menu/interface'

import { toastMessages } from '../constants/ToastMessages'
import { MarketingPopupConfigurations, MarketingPopupConfigurationsUIInterface } from '../interface/MarketingPopup'
import {
    setInitialPopupComponents,
    setNewPopupConfigs,
    setStoreValues,
} from '@store/kpDashboard/actions/marketingPopup'
import { setInitialLoginPopupComponents } from '@store/kpDashboard/actions/autoTriggerLogin'
import { derivedTrendsData } from '../interface/Analytics'
import { ListItem } from '@library/utilities/interface/LoginPopup'

const getDynamicSearchFilters = (searchFilters: SearchFilters[]): FilterCreatorModel => {
    const filterCreatorState: FilterCreatorModel = {
        title: 'Add More Filters +',
        options: searchFilters
            ?.filter((item) => item?.is_dynamic)
            ?.map((filter) => {
                return {
                    id: `${filter?.id}`,
                    text: filter?.label,
                }
            }),
    }
    return filterCreatorState
}

// Utility function to create a menu item in KPMenu
const createMenuItem = (
    label: ReactNode,
    key: string,
    icon?: ReactNode,
    children?: ItemType<MenuItemType>[],
    disabled: boolean = false,
    type?: 'group',
) => {
    return {
        key,
        icon,
        children,
        label,
        type,
        disabled,
    }
}

// Utility function to create a menu item in KPMenu
const createDivider = (): ItemType => {
    return { type: 'divider', key: null }
}

const getAllMerchantThemesParser = (resp) => {
    const list = resp?.data?.data
    const themeList = []
    list?.map((item) => {
        const obj = {
            id: item.id,
            value: item.name,
            role: item.role,
        }
        themeList.push(obj)
    })

    // Sort themeList in descending order of id(s)
    // As, latest published theme will have higher id-no
    themeList.sort((a, b) => b.id - a.id)

    return themeList
}

const getMartketingPopupEnabled = (pdp, home, collection) => {
    return home && pdp && collection
}
const convertIconRatioStringToArray_preview = (ratioString) => {
    return ratioString?.trim()?.split(':')?.map(Number)
}

const getMerchantConfigParser = (resp) => {
    const data = resp.data.data
    const configObj = {
        [MERCHANT_CONFIG_STORE_KEYS.KWIKPASS_ENABLED]: data?.[MERCHANT_CONFIG_STORE_KEYS.KWIKPASS_ENABLED],
        [MERCHANT_CONFIG_STORE_KEYS.HOST]: data?.host,
        [MERCHANT_CONFIG_STORE_KEYS.PLATFORM]: data?.[MERCHANT_CONFIG_STORE_KEYS.PLATFORM],
        [MERCHANT_CONFIG_STORE_KEYS.CATEGORY]: data?.[MERCHANT_CONFIG_STORE_KEYS.CATEGORY] || 'Others',
    }
    return configObj
}

const today = dayjs().format('YYYY-MM-DD')
const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
const weekAgo = dayjs().subtract(7, 'day').format('YYYY-MM-DD')

const getAssetsDefaultComponentParser = (resp) => {
    const popupDefaultAssetsData = resp.data?.data
    const {
        LOGIN_MODAL_ASSETS,
        AUTO_LOAD_POPUP2,
        AUTO_LOAD_POPUP3,
        AUTO_LOAD_POPUP4,
        AUTO_LOAD_POPUP5,
        AUTO_LOAD_POPUP6,
    } = COMPONENT_KEYS
    let defaultAssetConfigObj = {
        [LOGIN_MODAL_ASSETS]: null,
        [AUTO_LOAD_POPUP2]: null,
        [AUTO_LOAD_POPUP3]: null,
        [AUTO_LOAD_POPUP4]: null,
        [AUTO_LOAD_POPUP5]: null,
        [AUTO_LOAD_POPUP6]: null,
    }
    if (popupDefaultAssetsData.componentKey === LOGIN_MODAL_ASSETS) {
        defaultAssetConfigObj = {
            ...defaultAssetConfigObj,
            [LOGIN_MODAL_ASSETS]: popupDefaultAssetsData.properties,
        }
    } else if (popupDefaultAssetsData.componentKey === AUTO_LOAD_POPUP2) {
        defaultAssetConfigObj = {
            ...defaultAssetConfigObj,
            [AUTO_LOAD_POPUP2]: popupDefaultAssetsData.properties,
        }
    } else if (popupDefaultAssetsData.componentKey === AUTO_LOAD_POPUP3) {
        defaultAssetConfigObj = {
            ...defaultAssetConfigObj,
            [AUTO_LOAD_POPUP3]: popupDefaultAssetsData.properties,
        }
    } else if (popupDefaultAssetsData.componentKey === AUTO_LOAD_POPUP4) {
        defaultAssetConfigObj = {
            ...defaultAssetConfigObj,
            [AUTO_LOAD_POPUP4]: popupDefaultAssetsData.properties,
        }
    } else if (popupDefaultAssetsData.componentKey === AUTO_LOAD_POPUP5) {
        defaultAssetConfigObj = {
            ...defaultAssetConfigObj,
            [AUTO_LOAD_POPUP5]: popupDefaultAssetsData.properties,
        }
    } else if (popupDefaultAssetsData.componentKey === AUTO_LOAD_POPUP6) {
        defaultAssetConfigObj = {
            ...defaultAssetConfigObj,
            [AUTO_LOAD_POPUP6]: popupDefaultAssetsData.properties,
        }
    }
    return defaultAssetConfigObj
}
const getSassStyle = (object) => {
    return { ...object } as React.CSSProperties
}
const getComponentConfigsParser = (resp, key) => {
    const componentProperties = resp?.data?.data?.components[key].properties
    const componentId = resp?.data?.data?.components[key].id

    return { componentProperties, componentId }
}

const convertIconRatioStringToArray = (ratioString) => {
    return ratioString?.trim()?.split(':')
}

const getAuthorizationToken = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN_KEY)
}

const getFileTypeListInCommaSeparatedStringFormat = (fileType) => {
    if (FILE_TYPES_LIST[fileType] && FILE_TYPES_LIST[fileType].length > 0) {
        const fileTypeListInString = FILE_TYPES_LIST[fileType].join(',')
        return fileTypeListInString
    }
    return ''
}

const getPageOrigin = () => {
    const pageURL = window?.location?.href
    if (pageURL) {
        const url = new URL(pageURL)
        return url.origin
    }
    return null
}

const getParsedMerchantList = (merchantList) => {
    if (!merchantList) return []
    // returns in {id, value}
    let merchantListParsed = merchantList?.map((merchant) => {
        return {
            id: merchant.merchantId,
            value: merchant.displayName || merchant.name,
        }
    })
    return merchantListParsed
}
declare global {
    interface Window {
        useNewApi: boolean // Add your custom properties if needed
    }
}
const toTestNewAPIS = () => {
    return sessionStorage.getItem('useNewApi') == 'false' ? false : true
}

const downloadCsv = (csvData: string, filename: string) => {
    try {
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('hidden', '')
        a.setAttribute('href', url)
        a.setAttribute('download', filename)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        message.success(toastMessages.DOWNLOAD_INITIATED)
    } catch (error) {
        console.error('Error downloading CSV:', error)
        message.error(toastMessages.DOWNLOAD_FAILED)
    }
}

const objectToCsv = (data) => {
    const headers = Object.keys(data[0])
    const csvRows = []

    csvRows.push(headers.join(','))
    for (const row of data) {
        const values = headers.map((key) => {
            return row[key]
        })
        csvRows.push(values.join(','))
    }

    return csvRows.join('\n')
}

function cleanUpEmptyValues(a) {
    if (!a || typeof a !== 'object') {
        return a // If 'a' is not an object, return it as is
    }
    for (const key in a) {
        if (a[key] === null || a[key] === undefined || a[key] === '') {
            delete a[key]
        } else if (typeof a[key] === 'object') {
            a[key] = cleanUpEmptyValues(a[key]) // Recursively call removenull for nested objects
            if (Object.keys(a[key]).length === 0) {
                delete a[key] // If nested object becomes empty after removal, delete the key
            }
        }
    }
    return a
}
function isURL(str) {
    // Regular expression pattern to match URLs
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/

    // Test if the string matches the pattern
    return urlPattern.test(str)
}

const convPxValueToRem = (valueInPx) => {
    if (!valueInPx) return null
    const remValue = parseFloat(getComputedStyle(document.documentElement).fontSize)
    return (valueInPx / remValue).toFixed(3) + 'rem'
}
const convRemValueToPX = (valueInRem) => {
    const rem = parseFloat(valueInRem)
    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
    return rem * rootFontSize
}

const fallBackAssets = {
    carousel_isEnabled: true,
    carousel_list_items_font_size: '0.875rem',
    carousel_list_items_font_weight: '500',
    carousel_opacity_percent: 0.16,
    carousel_box_shadow_opacity: 0.08,
    carousel_slider_gradient_percent: 100,
    carousel_slider_border_opacity: 0.48,
    otp_screen_label_font_weight: '400',
    otp_screen_label_font_size: '0.875rem',
    otp_screen_heading_font_weight: '700',
    otp_screen_heading_font_size: '1.17em',
    login_screen_label_font_size: '0.875rem',
    login_screen_label_font_weight: '400',
    // below 2 (tx_L1 and tx_L2) are h3 elements
    login_screen_heading_text_line1_font_weight: '700',
    login_screen_heading_text_line2_font_weight: '700',
    login_screen_heading_text_line1_font_size: '1.25rem',
    login_screen_heading_text_line2_font_size: '1.25rem',
    login_screen_default_button_font_weight: '600',
    login_screen_default_button_font_size: '1rem',
    login_screen_placeholder_font_size: '1rem',
    login_screen_placeholder_font_weight: '500',
    login_screen_line_separator_font_size: '0.75rem',
    login_screen_line_separator_font_weight: '400',
    login_screen_line_separator_font_color: '#000',
    login_screen_marketing_checkbox_font_size: '0.75rem',
    login_screen_marketing_checkbox_font_weight: '400',
    login_screen_marketing_checkbox_color: '#667085',
    coupon_screen_code_font_size: '0.75rem',
    coupon_screen_code_font_weight: '700', //!600 on plugin
    coupon_screen_label_font_weight: '400',
    coupon_screen_coupon_description_font_size: '0.6875rem',
    coupon_screen_coupon_description_font_weight: '400',
    coupon_screen_coupon_text_font_size: '1rem',
    coupon_screen_coupon_text_font_weight: '700',
    coupon_screen_loader_message_font_size: '0.875rem',
    coupon_screen_loader_message_font_weight: '400',
    coupon_screen_success_message_text_line1_font_size: '1.25rem',
    coupon_screen_success_message_text_line1_font_weight: '700',
    coupon_screen_success_message_text_line2_font_size: '1.25rem',
    coupon_screen_success_message_text_line2_font_weight: '700',
    coupon_screen_dismiss_timeout: 3,
    // New params:
    promotion_text_font_weight: '700',
    promotion_text_font_size: '1.25rem',
    promotion_text_desktop_font_size: '1.25rem',
    promotion_text_mobile_font_size: '0.875rem',
    font_family: 'unset',
    form_bg_color: '#fff',
    gradient_percent: '52.5',
    submit_button_text: 'Submit',
    submit_button_font_color: '#000',
    submit_button_font_size: convPxValueToRem(14),
    submit_button_font_weight: '700', //!was 600 in plugin
    // MP6 assets
    offers_list_coupon_code_font_size: '18px',
    offers_list_coupon_code_font_weight: '600',
    offers_list_coupon_code_color: '#000',
    discounts_count_text_color: '#01b156',
    discounts_size_text_font_size: '14px',
    discounts_size_text_font_weight: '500',
    //GLOBALFONT ASSETS
    global_login_color: '#000',
    global_login_font_size: '1rem',
    global_login_font_weight: '400',
    global_login_show_login_asset: false,
    icon_ratio: '1:1',
    // SPIN THE WHEEL
    spin_wheel_heading_font_size: '1rem',
    spin_wheel_heading_font_weight: '700',
    spin_wheel_sub_heading_font_size: '0.875rem',
    spin_wheel_sub_heading_font_weight: '400',
    // APPLIED COUPON
    coupon_assets_label_text_font_size: '0.75rem',
    coupon_assets_label_text_font_weight: '400',
    info_text1_font_size: '0.75rem',
    info_text1_font_wight: '400', //
    // default font size
    font_4: 4,
    font_8: 8,
    font_12: 12,
    font_14: 14,
    font_16: 16,
    font_20: 20,
    //MP3
    marketing_popup_3_submit_button_bg_color: '#fff',
}
function getPreviousDay(dateString) {
    // Parse the input date string
    let date = new Date(dateString)

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        console.error('Invalid date format')
        throw new Error('Invalid date format')
    }

    // Subtract one day (24 hours in milliseconds)
    date.setDate(date.getDate() - 1)

    // Extract the components of the new date
    let year = date.getFullYear()
    let month = ('0' + (date.getMonth() + 1)).slice(-2) // Months are zero-indexed
    let day = ('0' + date.getDate()).slice(-2)

    //FORMAT CHECKS:
    // Match the input date format
    //The below format is specific to the current format being sent for analytics:
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
        // Format: YYYY-MM-DD
        return `${year}-${month}-${day}`
    } else {
        throw new Error('Unsupported date format')
    }
}
function getFilenameFromContentDisposition(header) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(header)
    if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '')
    }
    return null
}
const downloadExcel = async (response) => {
    const blob = response?.data
    const fileName = getFilenameFromContentDisposition(response?.headers?.['content-disposition'])
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
}
function generateString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ' '
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

function parseDataLayerOfMarketingPopupConfigutaionToUILayer(
    data_: MarketingPopupConfigurations,
): MarketingPopupConfigurationsUIInterface {
    const { name, platforms, pages, rules, userTargeting, triggerValidations } = data_ ?? {}
    const data: MarketingPopupConfigurationsUIInterface = {
        name: name,
        platforms: platforms,
        pages_pdp: pages?.pdp ?? false,
        pages_home: pages?.home ?? false,
        pages_collection: pages?.collection ?? false,
        pages_customUrls: pages?.customUrls ?? [],
        pages_toShowOnCustomUrls: pages?.toShowOnCustomUrls ?? true,
        rules_timeDelay_isEnabled: rules?.timeDelay?.isEnabled,

        rules_timeDelay_secondsAfterLanding: rules?.timeDelay?.secondsAfterLanding,
        rules_pageScrolls_isEnabled: rules?.pageScrolls?.isEnabled,
        rules_pageScrolls_scrollPercentage: rules?.pageScrolls?.scrollPercentage,
        rules_websiteExitIntent_isEnabled: rules?.websiteExitIntent?.isEnabled,
        rules_clickTrigger_isEnabled: rules?.clickTrigger?.isEnabled,
        rules_clickTrigger_target: rules?.clickTrigger?.target,
        rules_clickTrigger_type: rules?.clickTrigger?.type,
        rules_manualTrigger_isEnabled: rules?.manualTrigger?.isEnabled,
        rules_pagesVisited_isEnabled: rules?.pagesVisited?.isEnabled,
        rules_pagesVisited_pages: rules?.pagesVisited?.pages,
        rules_pagesVisited_condition: rules?.pagesVisited?.condition,
        triggerValidations_popupAppearLimit_isEnabled: triggerValidations?.popupAppearLimit?.isEnabled,
        triggerValidations_popupAppearLimit_attempt: triggerValidations?.popupAppearLimit?.attempt,
        triggerValidations_showToLoggedinUser_isEnabled: triggerValidations?.showToLoggedinUser?.isEnabled,
        userTargeting_utm_isEnabled: userTargeting?.utm?.isEnabled,
        userTargeting_utm_conditions: userTargeting?.utm?.conditions,
        userTargeting_cookie_isEnabled: userTargeting?.cookie?.isEnabled,
        userTargeting_cookie_conditions: userTargeting?.cookie?.conditions,
        userTargeting_visitsMerchant_isEnabled: userTargeting?.visitsMerchant?.isEnabled,
        userTargeting_visitsMerchant_condition: userTargeting?.visitsMerchant?.condition,
        userTargeting_visitsMerchant_count: userTargeting?.visitsMerchant?.count,
        userTargeting_visitsKpNetwork_isEnabled: userTargeting?.visitsKpNetwork?.isEnabled,
        userTargeting_visitsKpNetwork_condition: userTargeting?.visitsKpNetwork?.condition,
        userTargeting_visitsKpNetwork_count: userTargeting?.visitsKpNetwork?.count,
        userTargeting_merchantOrderCount_isEnabled: userTargeting?.merchantOrderCount?.isEnabled,
        userTargeting_merchantOrderCount_condition: userTargeting?.merchantOrderCount?.condition,
        userTargeting_merchantOrderCount_count: userTargeting?.merchantOrderCount?.count,
    }
    return data
}
function parseUILayerOfMarketingPopupConfigurationToDataLayer(ui: MarketingPopupConfigurationsUIInterface) {
    const data: MarketingPopupConfigurations = {
        name: ui.name,
        pages: {
            home: ui.pages_home,
            collection: ui.pages_collection,
            pdp: ui.pages_pdp,
            customUrls: ui.pages_customUrls,
            toShowOnCustomUrls: ui.pages_toShowOnCustomUrls,
        },
        platforms: ui.platforms,
        rules: {
            timeDelay: {
                isEnabled: ui.rules_timeDelay_isEnabled,
                secondsAfterLanding: ui.rules_timeDelay_secondsAfterLanding,
            },
            pageScrolls: {
                isEnabled: ui.rules_pageScrolls_isEnabled,
                scrollPercentage: ui.rules_pageScrolls_scrollPercentage,
            },
            websiteExitIntent: {
                isEnabled: ui.rules_websiteExitIntent_isEnabled,
            },
            clickTrigger: {
                isEnabled: ui.rules_clickTrigger_isEnabled,
                target: ui.rules_clickTrigger_target,
                type: ui.rules_clickTrigger_type,
            },
            manualTrigger: {
                isEnabled: ui.rules_manualTrigger_isEnabled,
            },
            pagesVisited: {
                isEnabled: ui.rules_pagesVisited_isEnabled,
                pages: ui.rules_pagesVisited_pages,
                condition: ui.rules_pagesVisited_condition,
            },
        },
        triggerValidations: {
            popupAppearLimit: {
                isEnabled: ui.triggerValidations_popupAppearLimit_isEnabled,
                attempt: ui.triggerValidations_popupAppearLimit_attempt,
            },
            showToLoggedinUser: {
                isEnabled: ui.triggerValidations_showToLoggedinUser_isEnabled,
            },
        },
        userTargeting: {
            utm: {
                isEnabled: ui.userTargeting_utm_isEnabled,
                conditions: ui.userTargeting_utm_conditions,
            },
            cookie: {
                isEnabled: ui.userTargeting_cookie_isEnabled,
                conditions: ui.userTargeting_cookie_conditions,
            },
            visitsMerchant: {
                isEnabled: ui.userTargeting_visitsMerchant_isEnabled,
                condition: ui.userTargeting_visitsMerchant_condition,
                count: ui.userTargeting_visitsMerchant_count,
            },
            visitsKpNetwork: {
                isEnabled: ui.userTargeting_visitsKpNetwork_isEnabled,
                condition: ui.userTargeting_visitsKpNetwork_condition,
                count: ui.userTargeting_visitsKpNetwork_count,
            },
            merchantOrderCount: {
                isEnabled: ui.userTargeting_merchantOrderCount_isEnabled,
                condition: ui.userTargeting_merchantOrderCount_condition,
                count: ui.userTargeting_merchantOrderCount_count,
            },
        },
    }
    return data
}

const validatMarketingPopupConfigurationsForm = (marketingPopupConfigurations) => {
    const isPageNotSelected =
        !marketingPopupConfigurations.pages?.home &&
        !marketingPopupConfigurations.pages?.collection &&
        !marketingPopupConfigurations.pages?.pdp &&
        (!marketingPopupConfigurations.pages?.customUrls ||
            marketingPopupConfigurations.pages?.customUrls?.length === 0)
    const isPlatformSelected = marketingPopupConfigurations?.platforms?.length > 0
    const isRulesAdded =
        marketingPopupConfigurations?.rules &&
        Object.keys(marketingPopupConfigurations.rules).some(
            (itm) => marketingPopupConfigurations.rules[itm]?.isEnabled,
        )
    if (
        !isPageNotSelected &&
        isPlatformSelected &&
        isRulesAdded &&
        marketingPopupConfigurations.name.trim().length > 0
    ) {
        return true
    }

    message.error(toastMessages.MANDATORY_FIELDS)
    return false
}

function storePopupAssets(data, componentKey, dispatch) {
    let isAssetsStored = false
    if (componentKey === COMPONENT_KEYS.AUTO_LOAD_POPUP4) {
        dispatch(setStoreValues(POPUP_STORES.INITAL_AP4_COMPONENTS, data))

        //dispath to set a new
        isAssetsStored = true
    } else if (componentKey === COMPONENT_KEYS.AUTO_LOAD_POPUP3) {
        // store auto-trigger-login screen configs in reducer
        dispatch(setStoreValues(POPUP_STORES.INITIAL_AP3_COMPONENTS, data))

        isAssetsStored = true
    } else if (componentKey === COMPONENT_KEYS.AUTO_LOAD_POPUP5) {
        dispatch(setInitialPopupComponents(POPUP_STORES.INITIAL_MP5_POPUP_COMPONENTS, data))
        dispatch(setNewPopupConfigs(POPUP_STORES.NEW_MP5_POPUP_COMPONENTS, data))
        isAssetsStored = true
    } else if (componentKey === COMPONENT_KEYS.AUTO_LOAD_POPUP6) {
        dispatch(setInitialPopupComponents(POPUP_STORES.INITIAL_MP6_POPUP_COMPONENTS, data))
        dispatch(setNewPopupConfigs(POPUP_STORES.NEW_MP6_POPUP_COMPONENTS, data))
        isAssetsStored = true
    } else if (componentKey === COMPONENT_KEYS.LOGIN_MODAL_ASSETS) {
        dispatch(setInitialLoginPopupComponents(data))
        isAssetsStored = true
    } else if (componentKey === COMPONENT_KEYS.AUTO_LOAD_POPUP2) {
        dispatch(setStoreValues(POPUP_STORES.INITIAL_AP2_POPUP_COMPONENTS, data))
        isAssetsStored = true
    }
    return isAssetsStored
}
function getDatesInRange(startDate, endDate) {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const dateArray = []
    let currentDate = start

    while (currentDate <= end) {
        dateArray.push(currentDate.toISOString().split('T')[0])
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return dateArray
}
function segregateDatesByWeek(startDate: string, endDate: string): string[] {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit' }

    const weeks: string[] = []
    let currentWeekStart = new Date(start)

    while (currentWeekStart <= end) {
        let currentWeekEnd = new Date(currentWeekStart)
        currentWeekEnd.setDate(currentWeekEnd.getDate() + 6)

        if (currentWeekEnd > end) {
            currentWeekEnd = new Date(end)
        }

        const weekRange = `${currentWeekStart.toLocaleDateString(
            'en-US',
            options,
        )} - ${currentWeekEnd.toLocaleDateString('en-US', options)}`
        weeks.push(weekRange)

        currentWeekStart.setDate(currentWeekStart.getDate() + 7)
    }

    return weeks
}
function segregateDatesByMonth(startDate: string, endDate: string): string[] {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const options: Intl.DateTimeFormatOptions = { month: 'short' }

    const months = new Set<string>()
    let currentDate = new Date(start)

    while (currentDate <= end) {
        const monthString = currentDate.toLocaleDateString('en-US', options)
        months.add(monthString)
        currentDate.setMonth(currentDate.getMonth() + 1)
        currentDate.setDate(1) // Set to the first day of the next month to avoid issues with months having different number of days
    }

    return Array.from(months)
}
function parseDate(dateStr: string): Date {
    return new Date(dateStr)
}

function sumValuesForWeekRange(dateRecords, weekRange) {
    const [startStr, endStr] = weekRange.split(' - ')
    const startDate = new Date(startStr + ' ' + new Date().getFullYear())
    const endDate = new Date(endStr + ' ' + new Date().getFullYear())

    const result = {
        total_unique_identified: 0,
        android: 0,
        ios: 0,
        mobile_others: 0,
        windows: 0,
        macintosh: 0,
        others: 0,
        android_app: 0,
        ios_app: 0,
    }

    dateRecords.forEach((record) => {
        const recordDate = parseDate(record.date)

        if (recordDate >= startDate && recordDate <= endDate) {
            result.total_unique_identified += parseInt(record.total_unique_identified, 10) || 0
            result.android += parseInt(record.android, 10) || 0
            result.ios += parseInt(record.ios, 10) || 0
            result.mobile_others += parseInt(record.mobile_others, 10) || 0
            result.windows += parseInt(record.windows, 10) || 0
            result.macintosh += parseInt(record.macintosh, 10) || 0
            result.others += parseInt(record.others, 10) || 0
            result.android_app += parseInt(record.android_app, 10) || 0
            result.ios_app += parseInt(record.ios_app, 10) || 0
        }
    })

    return result
}
function sumValuesForMonth(dateRecords, month) {
    const options: Intl.DateTimeFormatOptions = { month: 'short' }

    const result = {
        total_unique_identified: 0,
        android: 0,
        ios: 0,
        mobile_others: 0,
        windows: 0,
        macintosh: 0,
        others: 0,
        android_app: 0,
        ios_app: 0,
    }

    dateRecords.forEach((record) => {
        const recordDate = parseDate(record.date)
        const monthString = recordDate.toLocaleDateString('en-US', options)

        if (monthString === month) {
            result.total_unique_identified += parseInt(record.total_unique_identified, 10) || 0
            result.android += parseInt(record.android, 10) || 0
            result.ios += parseInt(record.ios, 10) || 0
            result.mobile_others += parseInt(record.mobile_others, 10) || 0
            result.windows += parseInt(record.windows, 10) || 0
            result.macintosh += parseInt(record.macintosh, 10) || 0
            result.others += parseInt(record.others, 10) || 0
            result.android_app += parseInt(record.android_app, 10) || 0
            result.ios_app += parseInt(record.ios_app, 10) || 0
        }
    })

    return result
}
function valuesForDate(dateRecords, targetDate: string) {
    const result = {
        total_unique_identified: 0,
        android: 0,
        ios: 0,
        mobile_others: 0,
        windows: 0,
        macintosh: 0,
        others: 0,
        android_app: 0,
        ios_app: 0,
    }

    dateRecords.forEach((record) => {
        const recordDate = parseDate(record.date)
        const recordDateStr = recordDate.toISOString().split('T')[0]
        if (recordDateStr === targetDate) {
            result.total_unique_identified += parseInt(record.total_unique_identified, 10) || 0
            result.android += parseInt(record.android, 10) || 0
            result.ios += parseInt(record.ios, 10) || 0
            result.mobile_others += parseInt(record.mobile_others, 10) || 0
            result.windows += parseInt(record.windows, 10) || 0
            result.macintosh += parseInt(record.macintosh, 10) || 0
            result.others += parseInt(record.others, 10) || 0
            result.android_app += parseInt(record.android_app, 10) || 0
            result.ios_app += parseInt(record.ios_app, 10) || 0
        }
    })

    return result
}
const deriveTrendsData = (data): derivedTrendsData => {
    let der_data: derivedTrendsData = {} as derivedTrendsData
    der_data.totalIdentification = data.total_unique_identified || 0
    //WEB
    der_data.totalWebIdentification = data.windows + data.macintosh + data.others || 0
    der_data.windows = data.windows || 0
    der_data.macintosh = data.macintosh || 0
    der_data.webOthers = data.others || 0

    //MOBILE
    der_data.totalMobileIdentification = data.ios + data.android + data.mobile_others || 0
    der_data.android = data.android || 0
    der_data.ios = data.ios || 0
    der_data.mobileOthers = data.mobile_others || 0

    //APP
    const totalAppIdentification =
        data.total_unique_identified -
            (data.windows + data.macintosh + data.others + data.ios + data.android + data.mobile_others) || 0
    der_data.totalAppIdentification = totalAppIdentification || 0
    der_data.androidApp = data.android_app || 0
    der_data.iosApp = data.ios_app || 0
    der_data.appOthers = totalAppIdentification - (data.android_app + data.ios_app) || 0
    return der_data
}
const formatDateValues = (dateString: string): string => {
    const date = new Date(dateString)

    if (isNaN(date.getTime())) {
        throw new Error('Invalid date string')
    }

    const options: Intl.DateTimeFormatOptions = {
        year: '2-digit',
        month: 'short',
        day: '2-digit',
    }

    const formattedDate = date.toLocaleDateString('en-US', options).replace(/, /g, ' ')
    const [month, day, year] = formattedDate.split(' ')
    return `${month} ${day}, ${year}`
}

const createListItems = (count: number): ListItem[] => {
    return Array.from({ length: count }, (_, index) => ({
        id: index + 1, // Use index + 1 for the id
        text: '', // Empty string for text
        font_size: 14, // Use index 14 for font_size
        font_weight: '', // Empty string for font_weight
        description: '', // Empty string for description
    }))
}

const getMappedValue = (key) => {
    const JSONMAP = {
        kp_whatsapp_logged_in: 'Login via WhatsApp',
        kp_phone_number_logged_in: 'Login via Phone number',
        kp_truecaller_logged_in: 'Login via Truecaller',
        kp_shopify_logged_in: 'Login to Shopify',
        kp_successfully_logged_out: 'Logout of Kwikpass',
    }
    return JSONMAP[key] || key
}
const getMappedValueForIdentifiers = (key) => {
    const json_map = {
        phone: 'Phone number',
        email: 'Email ID',
        customer_id: 'Customer ID',
    }
    return json_map[key] || key
}

export {
    getMappedValue,
    getMappedValueForIdentifiers,
    getParsedMerchantList,
    getDynamicSearchFilters,
    createMenuItem,
    createDivider,
    getAllMerchantThemesParser,
    getMerchantConfigParser,
    getAssetsDefaultComponentParser,
    getComponentConfigsParser,
    convertIconRatioStringToArray,
    getAuthorizationToken,
    getFileTypeListInCommaSeparatedStringFormat,
    getPageOrigin,
    today,
    yesterday,
    getSassStyle,
    convertIconRatioStringToArray_preview,
    toTestNewAPIS,
    weekAgo,
    downloadCsv,
    objectToCsv,
    cleanUpEmptyValues,
    isURL,
    getPreviousDay,
    fallBackAssets,
    convPxValueToRem,
    convRemValueToPX,
    downloadExcel,
    getFilenameFromContentDisposition,
    generateString,
    parseDataLayerOfMarketingPopupConfigutaionToUILayer,
    parseUILayerOfMarketingPopupConfigurationToDataLayer,
    validatMarketingPopupConfigurationsForm,
    storePopupAssets,
    getDatesInRange,
    segregateDatesByWeek,
    segregateDatesByMonth,
    sumValuesForMonth,
    sumValuesForWeekRange,
    valuesForDate,
    deriveTrendsData,
    formatDateValues,
    createListItems,
}
