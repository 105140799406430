import { DESKTOP_VIEW_MODE } from '@library/utilities/constants/constants'
import { SET_PREVIEW_VIEW_MODE } from '../types'

const initialState = {
    mode: DESKTOP_VIEW_MODE,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PREVIEW_VIEW_MODE:
            return {
                mode: action.payload,
            }
        default:
            return state
    }
}

export default reducer
