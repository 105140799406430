export const SET_MERCHANT_CONFIGS = 'SET_MERCHANT_CONFIGS'
export const SET_DEFAULT_COMPONENT_CONFIGS = 'SET_DEFAULT_COMPONENT_CONFIGS'
export const SET_PUBLISH_ACTION = 'SET_PUBLISH_ACTION'

// MERCHANT THEMES
export const SET_MERCHANT_THEMES_LIST = 'SET_MERCHANT_THEMES_LIST'
export const SELECT_MERCHANT_THEME = 'SELECT_MERCHANT_THEME'

// SCREEN:
export const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN'
export const SET_IS_SHOW_MARKETING_POPUPS_LIST = 'SET_IS_SHOW_MARKETING_POPUPS_LIST'

export const SET_PREVIEW_VIEW_MODE = 'SET_PREVIEW_VIEW_MODE'

// AUTO TRIGGER LOGIN SCREENS
// Is component config available
export const SET_AUTO_TRIGGER_POPUP_COMPONENT_ID = 'SET_AUTO_TRIGGER_POPUP_COMPONENT_ID'

// Initial state
export const SET_INITIAL_AUTO_TRIGGER_LOGIN_SCREEN_CONFIGS = 'INITIAL_LOGIN_POPUP_COMPONENTS'
export const SET_INITIAL_AUTO_TRIGGER_OTP_SCREEN_CONFIGS = 'SET_INITIAL_AUTO_TRIGGER_OTP_SCREEN_CONFIGS'
export const SET_INITIAL_AUTO_TRIGGER_COUPON_SCREEN_CONFIGS = 'SET_INITIAL_AUTO_TRIGGER_COUPON_SCREEN_CONFIGS'
// New state
export const SET_NEW_AUTO_TRIGGER_LOGIN_SCREEN_CONFIGS = 'NEW_LOGIN_POPUP_COMPONENTS'
export const SET_NEW_AUTO_TRIGGER_OTP_SCREEN_CONFIGS = 'SET_NEW_AUTO_TRIGGER_OTP_SCREEN_CONFIGS'
export const SET_NEW_AUTO_TRIGGER_COUPON_SCREEN_CONFIGS = 'SET_NEW_AUTO_TRIGGER_COUPON_SCREEN_CONFIGS'

// MARKETING POPUP SCREENS-AUTOLOAD POPUP 4
export const SET_AUTOLOAD_POPUP_4_COMPONENT_ID = 'SET_AUTOLOAD_POPUP_4_COMPONENT_ID'

// Initial State
export const SET_INITIAL_AUTOLOAD_POPUP_4_LOGIN_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_4_LOGIN_SCREEN_CONFIGS'
export const SET_INITIAL_AUTOLOAD_POPUP_4_OTP_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_4_OTP_SCREEN_CONFIGS'
export const SET_INITIAL_AUTOLOAD_POPUP_4_COUPON_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_4_COUPON_SCREEN_CONFIGS'
export const SET_INITIAL_AUTOLOAD_POPUP_5_LOGIN_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_5_LOGIN_SCREEN_CONFIGS'
export const SET_INITIAL_AUTOLOAD_POPUP_5_OTP_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_5_OTP_SCREEN_CONFIGS'
export const SET_INITIAL_AUTOLOAD_POPUP_5_COUPON_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_5_COUPON_SCREEN_CONFIGS'

// New state
export const SET_NEW_AUTOLOAD_POPUP_4_LOGIN_SCREEN_CONFIGS = 'SET_NEW_AUTOLOAD_POPUP_4_LOGIN_SCREEN_CONFIGS'
export const SET_NEW_AUTOLOAD_POPUP_4_OTP_SCREEN_CONFIGS = 'SET_NEW_AUTOLOAD_POPUP_4_OTP_SCREEN_CONFIGS'
export const SET_NEW_AUTOLOAD_POPUP_4_COUPON_SCREEN_CONFIGS = 'SET_NEW_AUTOLOAD_POPUP_4_COUPON_SCREEN_CONFIGS'

//

// MARKETING POPUP SCREENS-AUTOLOAD POPUP 3
export const SET_AUTOLOAD_POPUP_3_COMPONENT_ID = 'SET_AUTOLOAD_POPUP_3_COMPONENT_ID'
//initial state
export const SET_INITIAL_AUTOLOAD_POPUP_3_LOGIN_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_3_LOGIN_SCREEN_CONFIGS'
export const SET_INITIAL_AUTOLOAD_POPUP_3_OTP_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_3_OTP_SCREEN_CONFIGS'
export const SET_INITIAL_AUTOLOAD_POPUP_3_COUPON_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_3_COUPON_SCREEN_CONFIGS'
// New state
export const SET_NEW_AUTOLOAD_POPUP_3_LOGIN_SCREEN_CONFIGS = 'SET_NEW_AUTOLOAD_POPUP_3_LOGIN_SCREEN_CONFIGS'
export const SET_NEW_AUTOLOAD_POPUP_3_OTP_SCREEN_CONFIGS = 'SET_NEW_AUTOLOAD_POPUP_3_OTP_SCREEN_CONFIGS'
export const SET_NEW_AUTOLOAD_POPUP_3_COUPON_SCREEN_CONFIGS = 'SET_NEW_AUTOLOAD_POPUP_3_COUPON_SCREEN_CONFIGS'

// MARKETING POPUP SCREENS-AUTOLOAD POPUP 6
export const SET_AUTOLOAD_POPUP_6_COMPONENT_ID = 'SET_AUTOLOAD_POPUP_3_COMPONENT_ID'
//initial state
export const SET_INITIAL_AUTOLOAD_POPUP_6_LOGIN_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_6_LOGIN_SCREEN_CONFIGS'
export const SET_INITIAL_AUTOLOAD_POPUP_6_OTP_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_6_OTP_SCREEN_CONFIGS'
export const SET_INITIAL_AUTOLOAD_POPUP_6_COUPON_SCREEN_CONFIGS = 'SET_INITIAL_AUTOLOAD_POPUP_6_COUPON_SCREEN_CONFIGS'
// New state
export const SET_NEW_AUTOLOAD_POPUP_6_LOGIN_SCREEN_CONFIGS = 'SET_NEW_AUTOLOAD_POPUP_6_LOGIN_SCREEN_CONFIGS'
export const SET_NEW_AUTOLOAD_POPUP_6_OTP_SCREEN_CONFIGS = 'SET_NEW_AUTOLOAD_POPUP_6_OTP_SCREEN_CONFIGS'
export const SET_NEW_AUTOLOAD_POPUP_6_COUPON_SCREEN_CONFIGS = 'SET_NEW_AUTOLOAD_POPUP_6_COUPON_SCREEN_CONFIGS'

// Marketing Popup Configuration:
export const NEW_MARKETING_POPUP_CONFIGURATIONS = 'NEW_MARKETING_POPUP_CONFIGURATIONS'
export const INITIAL_MARKETING_POPUP_CONFIGURATIONS = 'INITIAL_MARKETING_POPUP_CONFIGURATIONS'

// FONTS
export const SET_FONTS_DATA = 'SET_FONTS_DATA'
export const POPUP_TYPE = 'POPUP_TYPE'

//ANALYTICS:
export const SET_ANALYTICS_DATE = 'SET_ANALYTICS_DATE'

//AP4"
