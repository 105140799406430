// Marketing Popup with Banner

import { MarketingPopup5Interface } from '@library/utilities/interface/MarketingPopup5'
import { POPUP_STORES } from '@library/utilities/constants/constants'
const initialState: MarketingPopup5Interface = {
    // Component Id
    [POPUP_STORES.MP5_POPUP_COMPONENTID]: null,
    // Initial or old screen-configs
    INITIAL_MP5_POPUP_COMPONENTS: {},
    // New screen-configs
    NEW_MP5_POPUP_COMPONENTS: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case POPUP_STORES.MP5_POPUP_COMPONENTID:
            return {
                ...state,
                [POPUP_STORES.MP5_POPUP_COMPONENTID]: action.payload,
            }

        case POPUP_STORES.INITIAL_MP5_POPUP_COMPONENTS:
            return {
                ...state,
                [POPUP_STORES.INITIAL_MP5_POPUP_COMPONENTS]: action.payload,
                [POPUP_STORES.NEW_MP5_POPUP_COMPONENTS]: {
                    ...action.payload,
                },
            }

        case POPUP_STORES.NEW_MP5_POPUP_COMPONENTS:
            return {
                ...state,
                [POPUP_STORES.NEW_MP5_POPUP_COMPONENTS]: action.payload,
            }

        default:
            return state
    }
}

export default reducer
