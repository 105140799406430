import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import filters from './filters'
import user from './user'
import merchant from './merchant'
import kpDashboard from './kpDashboard'
import configurations_merchant from './configurations_merchant'
import fonts_data from './fonts_data'
import analytics from './analytics'
import KpIntegration from './kpIntegration'

const rootReducer = combineReducers({
    filters,
    user,
    kpDashboard,
    merchant,
    configurations_merchant,
    fonts_data,
    analytics,
    KpIntegration,
})

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
})

export default store
export type RootStoreType = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
