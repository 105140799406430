// Set if component configs are available
const setComponentId = (type, isAvailable) => {
    if (type) {
        return {
            type,
            payload: isAvailable,
        }
    } else {
        null
    }
}

// Set New screen configs using form-values
const setNewPopupConfigs = (type, loginScreenConfig) => {
    return {
        type,
        payload: loginScreenConfig,
    }
}

const setInitialPopupComponents = (type, loginPopupConfigs) => {
    return {
        type,
        payload: loginPopupConfigs,
    }
}

const setStoreValues = (type, configs) => {
    return {
        type,
        payload: configs,
    }
}

export { setComponentId, setInitialPopupComponents, setNewPopupConfigs, setStoreValues }
