import { MERCHANT_CONFIG_STORE_KEYS } from '@library/utilities/constants/constants'
import { SET_MERCHANT_CONFIGS } from '../types'

const initialState = {
    [MERCHANT_CONFIG_STORE_KEYS.KWIKPASS_ENABLED]: true,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MERCHANT_CONFIGS:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}

export default reducer
