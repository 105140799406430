import { SCREEN_STORE_KEYS } from '@library/utilities/constants/constants'
import {
    SET_AUTOLOAD_POPUP_4_COMPONENT_ID,
    SET_INITIAL_AUTOLOAD_POPUP_4_LOGIN_SCREEN_CONFIGS,
    SET_NEW_AUTOLOAD_POPUP_4_LOGIN_SCREEN_CONFIGS,
    SET_NEW_AUTOLOAD_POPUP_4_OTP_SCREEN_CONFIGS,
    SET_NEW_AUTOLOAD_POPUP_4_COUPON_SCREEN_CONFIGS,
    SET_INITIAL_AUTOLOAD_POPUP_4_OTP_SCREEN_CONFIGS,
    SET_INITIAL_AUTOLOAD_POPUP_4_COUPON_SCREEN_CONFIGS,
} from '../types'

const initialState = {
    // Component Id
    [SCREEN_STORE_KEYS.COMPONENT_ID]: null,
    // Initial or old screen-configs
    [SCREEN_STORE_KEYS.INITIAL_LOGIN_SCREEN]: {},
    [SCREEN_STORE_KEYS.INITIAL_OTP_SCREEN]: {},
    [SCREEN_STORE_KEYS.INITIAL_COUPON_SCREEN]: {},
    // New screen-configs from form-values
    [SCREEN_STORE_KEYS.NEW_LOGIN_SCREEN]: {},
    [SCREEN_STORE_KEYS.NEW_OTP_SCREEN]: {},
    [SCREEN_STORE_KEYS.NEW_COUPON_SCREEN]: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTOLOAD_POPUP_4_COMPONENT_ID:
            return {
                ...state,
                [SCREEN_STORE_KEYS.COMPONENT_ID]: action.payload,
            }

        case SET_INITIAL_AUTOLOAD_POPUP_4_LOGIN_SCREEN_CONFIGS:
            return {
                ...state,
                [SCREEN_STORE_KEYS.INITIAL_LOGIN_SCREEN]: {
                    ...state[SCREEN_STORE_KEYS.INITIAL_LOGIN_SCREEN],
                    ...action.payload,
                },
                [SCREEN_STORE_KEYS.NEW_LOGIN_SCREEN]: {
                    ...state[SCREEN_STORE_KEYS.NEW_LOGIN_SCREEN],
                    ...action.payload,
                },
            }

        case SET_INITIAL_AUTOLOAD_POPUP_4_OTP_SCREEN_CONFIGS:
            return {
                ...state,
                [SCREEN_STORE_KEYS.INITIAL_OTP_SCREEN]: {
                    ...state[SCREEN_STORE_KEYS.INITIAL_OTP_SCREEN],
                    ...action.payload,
                },
                [SCREEN_STORE_KEYS.NEW_OTP_SCREEN]: {
                    ...state[SCREEN_STORE_KEYS.NEW_OTP_SCREEN],
                    ...action.payload,
                },
            }

        case SET_INITIAL_AUTOLOAD_POPUP_4_COUPON_SCREEN_CONFIGS:
            return {
                ...state,
                [SCREEN_STORE_KEYS.INITIAL_COUPON_SCREEN]: {
                    ...state[SCREEN_STORE_KEYS.INITIAL_COUPON_SCREEN],
                    ...action.payload,
                },
                [SCREEN_STORE_KEYS.NEW_COUPON_SCREEN]: {
                    ...state[SCREEN_STORE_KEYS.NEW_COUPON_SCREEN],
                    ...action.payload,
                },
            }

        case SET_NEW_AUTOLOAD_POPUP_4_LOGIN_SCREEN_CONFIGS:
            return {
                ...state,
                [SCREEN_STORE_KEYS.NEW_LOGIN_SCREEN]: {
                    ...state[SCREEN_STORE_KEYS.NEW_LOGIN_SCREEN],
                    ...action.payload,
                },
            }

        case SET_NEW_AUTOLOAD_POPUP_4_OTP_SCREEN_CONFIGS:
            return {
                ...state,
                [SCREEN_STORE_KEYS.NEW_OTP_SCREEN]: {
                    ...state[SCREEN_STORE_KEYS.NEW_OTP_SCREEN],
                    ...action.payload,
                },
            }

        case SET_NEW_AUTOLOAD_POPUP_4_COUPON_SCREEN_CONFIGS:
            return {
                ...state,
                [SCREEN_STORE_KEYS.NEW_COUPON_SCREEN]: {
                    ...state[SCREEN_STORE_KEYS.NEW_COUPON_SCREEN],
                    ...action.payload,
                },
            }

        default:
            return state
    }
}

export default reducer
