import { SELECT_MERCHANT_THEME, SET_MERCHANT_THEMES_LIST } from '../types'

const initialState = {
    themeList: [],
    selectedThemeObj: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MERCHANT_THEMES_LIST:
            return {
                ...state,
                themeList: action.payload,
            }

        case SELECT_MERCHANT_THEME:
            return {
                ...state,
                selectedThemeObj: action.payload,
            }

        default:
            return state
    }
}

export default reducer
